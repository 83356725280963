import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export default function Watermeter() {
	const {userInfo} = useSelector((state) => state.auth);
	const [lorawanInfo, setLorawanInfo] = useState(null);

	useEffect(() => {
		// Recuperar datos de localStorage y convertirlos a un objeto
		const storedLorawanInfo = localStorage.getItem("lorawanInfo");
		if (storedLorawanInfo) {
			setLorawanInfo(JSON.parse(storedLorawanInfo));
		}
	}, []);

	// console.log(lorawanInfo);

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				{/* <div className="gray-header-background"></div> */}
				<div className="my-profile-container">
					<h1 className="my-profile-title">Mis medidor</h1>
					<div className="non-editable-information-container">
						<div className="info-container">
							<h2 className="info-title">Localidad</h2>
							<h3 className="info-text">{userInfo?.sector_name}</h3>
						</div>
						<div className="info-container">
							<h2 className="info-title">Municipalidad</h2>
							<h3 className="info-text">{userInfo?.comuna_name}</h3>
						</div>
						<div className="info-container">
							<h2 className="info-title">Region</h2>
							<h3 className="info-text">{userInfo?.region_name}</h3>
						</div>
						<div className="info-container">
							<h2 className="info-title">APR</h2>
							<h3 className="info-text">{userInfo?.apr_name}</h3>
						</div>
						<div className="info-container">
							<h2 className="info-title">Tarifa</h2>
							<h3 className="info-text">{userInfo?.tarifa_nombre}</h3>
						</div>
						<div className="info-container">
							<h2 className="info-title">{userInfo?.tarifa_tope === "0.0" ? "Subsidiado" : "No subsidiado"}</h2>
						</div>
					</div>
					<div className="second-non-editable-information-container">
						<h1 className="second-non-editable-information-title">Datos de medidor</h1>
						<div className="info-content">
							<div className="info-container">
								<h2 className="info-title">Estado</h2>
								<h3 className="info-text">{lorawanInfo?.data?.ValveStatus === 1 ? "Habilitado" : "Deshabilitado"}</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Ultima Facturacion</h2>
								<h3 className="info-text">Lunes 22 de marzo</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Consumo por pagar</h2>
								<h3 className="info-text">3.4 m³</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Fecha ultima lectura</h2>
								<h3 className="info-text">{lorawanInfo?.data?.ReadDate}</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Ultima lectura</h2>
								<h3 className="info-text">{lorawanInfo?.data?.MeterNumber}m³</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Voltaje</h2>
								<h3 className="info-text">{lorawanInfo?.data?.Voltage}</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Estado de pago</h2>
								<h3 className="info-text">Por pagar</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Consumo este mes</h2>
								<h3 className="info-text">3.03 m³</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Consumo hoy</h2>
								<h3 className="info-text">0.9 m³</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
