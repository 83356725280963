import { Button, Input, Space, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../images/plus-icon.svg";
import spreadsheetIcon from "../../../images/spreadsheet-white.svg";


export default function MonthlyLectures() {
	const [data, setData] = useState([]);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const navigate = useNavigate();

	const handlePayClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
		navigate(`/menu_apr/pay/`);
	};
	const handleCancelClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}
					>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "Año",
			dataIndex: "year",
			key: "year",
			width: "10%",
			...getColumnSearchProps("year"),
		},
		{
			title: "Mes",
			dataIndex: "month",
			key: "month",
			width: "10%",
			...getColumnSearchProps("month"),
		},
		{
			title: "M³",
			dataIndex: "cubic_meter",
			key: "cubic_meter",
			width: "10%",
			...getColumnSearchProps("cubic_meter"),
			sorter: (a, b) => a.rut.length - b.rut.length,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Total mes",
			dataIndex: "total_month",
			key: "total_month",
			width: "15%",
			...getColumnSearchProps("total_month"),
		},
		{
			title: "Saldo por pagar",
			dataIndex: "outstanding_balance",
			key: "outstanding_balance",
			width: "15%",
			...getColumnSearchProps("outstanding_balance"),
		},
		{
			title: "Acción",
			dataIndex: "edit",
			key: "edit",
			render: (_, record) => (
				<div className="actions-container">
					<a className="active-link" onClick={() => handlePayClick(record.key)}>
						Pagar
					</a>
					<a className="active-link" onClick={() => handleCancelClick(record.key)}>
						Anular Pago
					</a>
				</div>
			),
		},
	];

	useEffect(() => {
		// Datos de ejemplo para la tabla
		const mockData = [
			{
				key: 1,
				year: "2024",
				month: "Enero",
				cubic_meter: 30,
				total_month: 45000,
				outstanding_balance: 15000,
			},
			{
				key: 2,
				year: "2024",
				month: "Febrero",
				cubic_meter: 25,
				total_month: 37500,
				outstanding_balance: 10000,
			},
			{
				key: 3,
				year: "2024",
				month: "Marzo",
				cubic_meter: 20,
				total_month: 30000,
				outstanding_balance: 0,
			},
			{
				key: 4,
				year: "2024",
				month: "Abril",
				cubic_meter: 35,
				total_month: 52500,
				outstanding_balance: 20000,
			},
		];

		setData(mockData); // Actualiza el estado con los datos simulados
	}, []);

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<div className="user-info-container">
						<h3 className="info-text">
							Nombre: <span className="marked">Example</span>
						</h3>
						<h3 className="info-text">
							N° Medidor: <span className="marked">Example</span>
						</h3>
						<h3 className="info-text">
							RUT: <span className="marked">Example</span>
						</h3>
						<h3 className="info-text">
							Dirección: <span className="marked">Example</span>
						</h3>
						<h3 className="info-text">
							Estado medidor: <span className="marked">Example</span>
						</h3>
					</div>
					{/* <div className="buttons-container">
						<div className="add-button-container">
							<img className="plus-icon" src={plusIcon} />
							<a className="add-button" onClick={() => handleAddClick("")}>
								Añadir movimiento
							</a>
						</div>
					</div> */}
				</div>
				<Table columns={columns} dataSource={data} />
			</div>
		</div>
	);
}
