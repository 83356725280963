import { Button, Input, Space, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../images/plus-icon.svg";
import spreadsheetIcon from "../../../images/spreadsheet-white.svg";

export default function ClientsMovements() {
	const [data, setData] = useState([]);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const navigate = useNavigate();

	const handleEditClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
	};
	const handleAddClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
	};
	const handleSpreadsheetClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
		navigate(`/menu_apr/requirements-spreadsheets/`);

	};

	const handleMonthlyLecturesClick = (userId) => {
		// navigate(`/menu_apr/consumptions/${userId}`);
		navigate(`/menu_apr/monthly-lectures/1`);

	};

	const handleAgreementClick = (userId) => {
		// navigate(`/menu_apr/consumptions/${userId}`);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}
					>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "N° Cliente",
			dataIndex: "client_number",
			key: "client_number",
			width: "10%",
			...getColumnSearchProps("client_number"),
		},
		{
			title: "N° Medidor",
			dataIndex: "watermeter_number",
			key: "watermeter_number",
			width: "20%",
			...getColumnSearchProps("watermeter_number"),
		},
		{
			title: "Rut",
			dataIndex: "rut",
			key: "rut",
			width: "25%",
			...getColumnSearchProps("rut"),
			sorter: (a, b) => a.rut.length - b.rut.length,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			width: "15%",
			...getColumnSearchProps("name"),
		},
		{
			title: "Estado",
			dataIndex: "state",
			key: "state",
			width: "10%",
			...getColumnSearchProps("state"),
			render: (_, record) => (
				<Space size="middle">
					{record.state === "Activo" ? (
						<div className="active-state">Activo</div>
					) : record.state === "Inactivo" ? (
						<div className="inactive-state">Inactivo</div>
					) : record.state === "Revisar" ? (
						<div className="review-state">Revisión</div>
					) : (
						""
					)}
				</Space>
			),
		},
		{
			title: "Acción",
			dataIndex: "edit",
			key: "edit",
			render: (_, record) => (
				<div className="actions-container">
					<a className="active-link" onClick={() => handleEditClick(record.key)}>
						Editar usuario
					</a>
					<a className="active-link" onClick={() => handleMonthlyLecturesClick(record.key)}>
						Consumos
					</a>
					<a className="active-link" onClick={() => handleAgreementClick(record.key)}>
						Convenio
					</a>
				</div>
			),
		},
	];

	useEffect(() => {
		// Datos de ejemplo para la tabla
		const mockData = [
			{
				key: 1,
				client_number: "C001",
				watermeter_number: "WM1001",
				rut: "12345678-9",
				name: "Juan Pérez",
				state: "Activo",
			},
			{
				key: 2,
				client_number: "C002",
				watermeter_number: "WM1002",
				rut: "98765432-1",
				name: "María González",
				state: "Inactivo",
			},
			{
				key: 3,
				client_number: "C003",
				watermeter_number: "WM1003",
				rut: "12398765-4",
				name: "Carlos López",
				state: "Revisar",
			},
			{
				key: 4,
				client_number: "C004",
				watermeter_number: "WM1004",
				rut: "11223344-5",
				name: "Ana Torres",
				state: "Activo",
			},
		];

		setData(mockData); // Actualiza el estado con los datos simulados
	}, []);

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<h1 className="container-title">Movimientos clientes</h1>
					<div className="buttons-container">
						<div className="add-button-container">
							<img className="plus-icon" src={spreadsheetIcon} />
							<a className="add-button" onClick={() => handleSpreadsheetClick("")}>
								Obtener planilla movimientos mes
							</a>
						</div>
						<div className="add-button-container">
							<img className="plus-icon" src={plusIcon} />
							<a className="add-button" onClick={() => handleAddClick("")}>
								Añadir movimiento
							</a>
						</div>
					</div>
				</div>
				<Table columns={columns} dataSource={data} />
			</div>
		</div>
	);
}
