import React, {useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import editIcon from "../../images/edit-icon-blue.svg";
import {BACKEND_DOMAIN} from "../../features/auth/authService";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Profile() {
	const {userInfo} = useSelector((state) => state.auth);
	const navigate = useNavigate();

	// console.log("userInfo", userInfo)

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		birthday: "",
	});

	const {first_name, last_name, email, password, birthday} = formData;

	const handleChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Crear objeto con solo los campos que han sido llenados
		const dataToUpdate = {};

		if (first_name.trim()) dataToUpdate.first_name = first_name;
		if (last_name.trim()) dataToUpdate.last_name = last_name;
		if (email.trim()) dataToUpdate.email = email;
		if (birthday.trim()) dataToUpdate.birthday = birthday;
		if (password.trim()) dataToUpdate.password = password;

		// Obtener el token desde localStorage
		const user = JSON.parse(localStorage.getItem("user"));
		const token = user ? user.access : null;
		// console.log(JSON.parse(localStorage.getItem("user")))

		// Solo hacer la solicitud si hay datos a actualizar y hay un token
		if (Object.keys(dataToUpdate).length > 0 && token) {
			try {
				const response = await axios.patch(`${BACKEND_DOMAIN}/api/v1/auth/users/me/`, dataToUpdate, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				// console.log("Datos actualizados con éxito:", response.data);

				toast.success("Cambios Exitosos");

				// Redirigir a /menu después de 2 segundos
				setTimeout(() => {
					navigate("/menu");
				}, 2000);
			} catch (error) {
				console.error("Error al actualizar los datos:", error.response ? error.response.data : error.message);
				// Mostrar mensaje de error
				toast.error("Error al actualizar los datos");
			}
		} else {
			console.log("No hay datos para actualizar o falta el token.");
		}
	};

	return (
		<>
			<div className="menu-container">
				<div className="menu-content-container">
					<div className="my-profile-container">
						<h1 className="my-profile-title">Mis datos de usuario</h1>
						<div className="non-editable-information-container">
							<div className="info-container">
								<h2 className="info-title">Localidad</h2>
								<h3 className="info-text">{userInfo?.sector_name}</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Municipalidad</h2>
								<h3 className="info-text">{userInfo?.comuna_name}</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Region</h2>
								<h3 className="info-text">{userInfo?.region_name}</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">APR</h2>
								<h3 className="info-text">{userInfo?.apr_name}</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">Tarifa</h2>
								<h3 className="info-text">{userInfo?.tarifa_nombre}</h3>
							</div>
							<div className="info-container">
								<h2 className="info-title">{userInfo?.tarifa_tope === "0.0" ? "Subsidiado" : "No subsidiado"}</h2>
							</div>
						</div>
						<div className="editable-information-container">
							<div className="editable-information-header">
								<h1 className="editable-information-title">Datos personales</h1>
								<img className="edit-icon" src={editIcon} />
							</div>
							<form className="edit-form" onSubmit={handleSubmit}>
								<div className="input-container">
									<h2 className="input-title">Nombre</h2>
									<input className="input-box" type="text" placeholder="Introduce tu nombre" name="first_name" onChange={handleChange} value={first_name} />
								</div>
								<div className="input-container">
									<h2 className="input-title">Apellido</h2>
									<input className="input-box" type="text" placeholder="Introduce tu apellido" name="last_name" onChange={handleChange} value={last_name} />
								</div>
								<div className="input-container">
									<h2 className="input-title">Email</h2>
									<input className="input-box" type="email" placeholder="Introduce tu email" name="email" onChange={handleChange} value={email} />
								</div>
								<div className="input-container">
									<h2 className="input-title">Contraseña</h2>
									<input
										className="input-box"
										type="password"
										placeholder="Introduce tu nueva contraseña"
										name="password"
										onChange={handleChange}
										value={password}
									/>
								</div>
								<div className="input-container">
									<h2 className="input-title">Nacimiento</h2>
									<input className="input-box" type="date" name="birthday" onChange={handleChange} value={birthday} />
								</div>
								<button className="btn-primary" type="submit">
									Actualizar
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
