import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Contact() {
	return (
		<>
			<Helmet>
				<title>Contact</title>
				<meta name="description" content="This is contact page" />
			</Helmet>
			<h1>Contact</h1>
			<hr />
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
				quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
				dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
			</p>
			<p>
				Simple Links: <Link to="/">Home</Link>, <Link to="/about">About</Link>, <Link to="contact">Contact</Link>
			</p>
		</>
	);
}
