import {Link, NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";

import "../stylesheets/home.scss";

// IMAGE IMPORT
import introMainGraph from "../images/intro-main-image.svg";
import divisorLine from "../images/divisor-line.svg";
import learnArrow from "../images/learn-more-arrow.svg";
import sandclockIcon from "../images/blue-sandclock-icon.svg";
import calendarIcon from "../images/blue-calendar-icon.svg";
import gpsIcon from "../images/blue-gps-icon.svg";
import precisionFunction from "../images/precision-functions-image.svg";
import precisionBottomImage from "../images/precision-functions-bottom-image.svg";
import waterMonitoring from "../images/water-monitoring-bottom-image.svg";
import whiteDivisor from "../images/white-divisor.svg";
import roundDroplets from '../images/round-droplets.svg'
import programCount from '../images/program-function-image.webp'

export default function Home() {
	return (
		<>
			<Helmet>
				<title>Home</title>
				<meta name="description" content="This is homepage" />
			</Helmet>
			<div className="home-container">
				<div className="intro-container">
					<div className="intro-text-container">
						<h1 className="intro-main-text">
							MyF hace el control de tu <br />
							Sistema de agua <br />
							Accesible y sencillo.
						</h1>
						<h2 className="intro-secondary-text">
							Control en tiempo real sobre calidad de presión, estado de válvulas ,<br /> cobro y pago de consumos, medidores inteligentes, consulta de datos,{" "}
							<br /> compra de insumos - todo mientras ahorras tiempo y dinero.
						</h2>
						<div className="login-button-container">
							<NavLink className="login-button" to="/login" activeclass="active">
								Iniciar Sesión
							</NavLink>
						</div>
					</div>
					<div className="intro-image-container">
						<img src={introMainGraph} className="intro-main-image" />
					</div>
				</div>
				<div className="problem-solver-container">
					<div className="problem-solver-left-container">
						<h1 className="problem-solver-main-text">
							Problemas resueltos
							<br />
							Antes de que sucedan
						</h1>
						<img src={divisorLine} />
						<h2 className="problem-solver-secondary-text">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
						</h2>
						<div className="learn-more-container">
							<div className="learn-more-content-container">
								<img className="icon-image" src={sandclockIcon} />
								<h2 className="learn-more-title">Revisa informacion en tiempo real</h2>
								<h3 className="learn-more-description">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								</h3>
								<NavLink to={"/learn_more"} className="link-container">
									<h3 className="link-text">Aprender mas</h3>
									<img className="link-arrow" src={learnArrow} />
								</NavLink>
							</div>
						</div>
					</div>
					<div className="problem-solver-right-container">
						<div className="learn-more-container">
							<div className="learn-more-content-container">
								<img className="icon-image" src={calendarIcon} />
								<h2 className="learn-more-title">Organiza el horario de control </h2>
								<h3 className="learn-more-description">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								</h3>
								<NavLink to={"/learn_more"} className="link-container">
									<h3 className="link-text">Aprender mas</h3>
									<img className="link-arrow" src={learnArrow} />
								</NavLink>
							</div>
							<div className="learn-more-content-container">
								<img className="icon-image" src={gpsIcon} />
								<h2 className="learn-more-title">Monitorea desde donde sea</h2>
								<h3 className="learn-more-description">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								</h3>
								<NavLink to={"/learn_more"} className="link-container">
									<h3 className="link-text">Aprender mas</h3>
									<img className="link-arrow" src={learnArrow} />
								</NavLink>
							</div>
						</div>
					</div>
				</div>
				<div className="precision-functions-container">
					<div className="precision-functions-text-container">
						<h1 className="precision-functions-title">
							Precision profesional en el calculo de gastos
							<br />
							Datos en tiempo real
						</h1>
						<img src={divisorLine} />
						<h2 className="precision-functions-description">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
						</h2>
						<div className="functions-button-container">
							<NavLink className="functions-button" to="/functions" activeclass="active">
								Ver Funciones
							</NavLink>
						</div>
					</div>
					<div className="precision-functions-image-container">
						<img className="precision-functions-image" src={precisionFunction} />
					</div>
					<div className="precision-functions-bottom-container">
						<img className="precision-functions-bottom-image" src={precisionBottomImage} />
					</div>
				</div>
				<div className="water-monitoring-container">
					<h1 className="water-monitoring-title">
						Solución de monitoreo de agua
						<br /> totalmente integrada
					</h1>
					<img className="white-divisor" src={whiteDivisor} />
					<h2 className="water-monitoring-description">
						MyF es una plataforma totalmente integrada que combina hardware, software, conectividad, informes automatizados y mantenimiento para automatizar el
						monitoreo y las pruebas de su medidor de agua. MyF permite a los operadores de agua identificar y resolver desafíos de calidad y eficiencia del agua
						de misión crítica en tiempo real para garantizar que sus reportes de agua cumpla con estándares específicos de certificación y calidad.
					</h2>
					<img src={waterMonitoring} className="water-monitoring-bottom-image" />
				</div>
				<div className="programs-counts-container">
					<div className="programs-counts-text-container">
						<img className="programs-counts-drops-round" src={roundDroplets}/>
						<h1 className="precision-functions-title">
							Precisión profesional de
							<br /> control y monitoreo del <br /> agua
						</h1>
						<img src={divisorLine} />
						<h2 className="programs-counts-description">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
						</h2>
					</div>
					<div className="programs-counts-image-container">
						<img className="programs-counts-image" src={programCount} />
					</div>
				</div>
			</div>
			{/* <p>
				Simple Links: <Link to="/">Home</Link>, <Link to="/about">About</Link>, <Link to="contact">Contact</Link>
			</p> */}
		</>
	);
}
