import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";
import { BACKEND_DOMAIN } from "../../features/auth/authService";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../images/plus-icon.svg";


const UserLists = () => {
	const [data, setData] = useState([]); // Estado para almacenar los medidores
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const searchInput = useRef(null);
	const navigate = useNavigate();

	const handleEditClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		navigate(`/menu_apr/editar-usuario/${userId}`);
	};

	const handleConsumptionsClick = (userId) => {
		navigate(`/menu_apr/consumptions/${userId}`);
	};

	const handleAddClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
		navigate(`/menu_apr/add-user/`);
	};

	useEffect(() => {
		const fetchMedidores = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				const token = user?.access;

				if (!token) {
					throw new Error("No se encontró el token en el localStorage");
				}

				const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/getmedidores/apr/`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				const medidores = response.data;

				// Mapeo de los datos a la estructura requerida por antd
				const mappedData = medidores.map((medidor, index) => ({
					key: medidor.user.user_id,
					name: `${medidor.user.first_name} ${medidor.user.last_name}`,
					state: medidor.consumo.valve_status === "Opened" ? "Activo" : "Inactivo",
					rut: formatRut(medidor.user.rut),
					email: medidor.user.email,
					address: medidor.user.address,
					sector: medidor.user.sector,
					edit: "Editar",
				}));

				setData(mappedData);
				console.log("mappedData", mappedData);
				localStorage.setItem("medidoresData", JSON.stringify(mappedData)); // Almacenar los nuevos datos en localStorage
				// }
			} catch (error) {
				console.error("Error fetching medidores:", error);
			}
		};

		fetchMedidores();
	}, []);

	const formatRut = (rut) => {
		// Convertir el rut a string para asegurar su manipulación como cadena
		const rutStr = String(rut).trim();

		// Obtener el dígito verificador y los dígitos del rut
		const rutDigits = rutStr.slice(0, -1);
		const dv = rutStr.slice(-1);

		// Formatear los dígitos con puntos
		let formattedRut = rutDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

		// Agregar el dígito verificador
		formattedRut = `${formattedRut}-${dv}`;

		return formattedRut;
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}
					>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			width: "15%",
			...getColumnSearchProps("name"),
		},
		{
			title: "Estado",
			dataIndex: "state",
			key: "state",
			width: "10%",
			...getColumnSearchProps("state"),
			render: (_, record) => (
				<Space size="middle">
					{record.state === "Activo" ? (
						<div className="active-state">Activo</div>
					) : record.state === "Inactivo" ? (
						<div className="inactive-state">Inactivo</div>
					) : record.state === "Revisar" ? (
						<div className="review-state">Revisión</div>
					) : (
						""
					)}
				</Space>
			),
		},
		{
			title: "Rut Asociado",
			dataIndex: "rut",
			key: "rut",
			width: "15%",
			...getColumnSearchProps("rut"),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			width: "15%",
			...getColumnSearchProps("email"),
		},
		{
			title: "Dirección",
			dataIndex: "address",
			key: "address",
			width: "20%",
			...getColumnSearchProps("address"),
			sorter: (a, b) => a.address.length - b.address.length,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Sector",
			dataIndex: "sector",
			key: "sector",
			width: "10%",
			...getColumnSearchProps("sector"),
		},
		{
			title: "Acción",
			dataIndex: "edit",
			key: "edit",
			render: (_, record) => (
				<div className="actions-container">
					<a className="active-link" onClick={() => handleEditClick(record.key)}>
						Editar
					</a>
					<a className="active-link" onClick={() => handleConsumptionsClick(record.key)}>
						Consumos
					</a>
				</div>
			),
		},
	];

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<h1 className="container-title">Usuarios</h1>
					<div className="add-button-container">
						<img className="plus-icon" src={plusIcon} />
						<a className="add-button" onClick={() => handleAddClick("")}>
							Añadir usuario
						</a>
					</div>
				</div>
				<Table columns={columns} dataSource={data} />
			</div>
		</div>
	);
};

export default UserLists;
