import {NavLink, useNavigate} from "react-router-dom";

import logoHeader from "../images/logo.svg";
import {logout, reset} from "../features/auth/authSlice";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";

import "../stylesheets/nav.scss";
import {useEffect} from "react";

export default function Nav() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {user, userInfo} = useSelector((state) => state.auth);

	const handleLogout = () => {
		dispatch(logout());
		dispatch(reset());
		navigate("/");
	};

	useEffect(() => {
		// console.log("userInfo", userInfo)
		// console.log("user", user)
	}, [user]);

	return (
		<div className="header-menu">
			<NavLink to="/" activeclass="active">
				<div className="logo-container">
					<img className="logo-image" src={logoHeader} />
				</div>
			</NavLink>
			<ul className="lista-nav">
				<li>
					<NavLink to="/solutions" activeclass="active">
						Soluciones
					</NavLink>
				</li>
				<li>
					<NavLink to="/resources" activeclass="active">
						Recursos
					</NavLink>
				</li>
				<li>
					<NavLink to="/about" activeclass="active">
						Acerca de nosotros
					</NavLink>
				</li>
				<li>
					<NavLink to="/contact" activeclass="active">
						Contáctanos
					</NavLink>
				</li>
				{user ? (
					<li>
						<NavLink className="login" to="/menu" /* onClick={handleLogout} */>
							Mi Perfil
						</NavLink>
					</li>
				) : (
					<li>
						<NavLink className="login" to="/login" activeclass="active">
							Iniciar Sesión
						</NavLink>
					</li>
				)}
			</ul>
		</div>
	);
}
