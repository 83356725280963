import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { BACKEND_DOMAIN } from "../../../../features/auth/authService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";

export default function MovementEdit() {
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		code: "",
		movement_description: "",
	});

	const handleChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
	};

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="my-profile-container">
					<div className="form-container">
						<h1 className="form-title">Editar Movimiento</h1>
						<form className="edit-user-form" onSubmit={handleSubmit}>
							<div className="input-container">
								<label>Codigo movimiento</label>
								<input type="text" name="code" value={formData.code} onChange={handleChange} />
							</div>
							<div className="input-container">
								<label>Descripción de movimiento</label>
								<input
									type="text"
									name="movement_description"
									value={formData.movement_description}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								<label>Tipo de movimiento:</label>
								<select name="id_medidor" value={formData.applied_to} onChange={handleChange}>
									<option value="">D=Deber / H=Haber </option>
									{/* {medidores.map((medidor) => ( */}
									<option key={1} value={"debit"}>
										{/* {medidor.meter_addr} */}
										Debe
									</option>
									<option key={2} value={"credit"}>
										{/* {medidor.meter_addr} */}
										Haber
									</option>
									{/* ))} */}
								</select>
							</div>
							<div className="buttons-container">
								<button type="button" className="btn-cancel" onClick={() => navigate("/menu_apr")}>
									<img src={cancelIcon} className="cancel-image" />
									Cancelar
								</button>
								<button type="submit" className="btn-save">
									<img src={saveDisk} className="save-image" />
									Guardar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
