import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { BACKEND_DOMAIN } from "../../../../features/auth/authService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";

export default function FareEdit() {
	const navigate = useNavigate();
	const { userId } = useParams(); // Extraer userId desde la URL

	const [formData, setFormData] = useState({
		code: "",
		outlet_description: "",
		maintenance_charge: "",
		sewer_charge: "",
	});

	const handleChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
	};

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="my-profile-container">
					<div className="form-container">
						<h1 className="form-title">Editar Tarifa</h1>
						<form className="edit-user-form" onSubmit={handleSubmit}>
							<div className="input-container">
								<label>Codigo tarifa</label>
								<input type="text" name="code" value={formData.code} onChange={handleChange} />
							</div>
							<div className="input-container">
								<label>Descripción de tarifa</label>
								<input
									type="text"
									name="outlet_description"
									value={formData.outlet_description}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								<label>Descuento (%)</label>
								<input
									type="text"
									name="maintenance_charge"
									value={formData.maintenance_charge}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								<label>Monto máximo de descuento</label>
								<input
									type="text"
									name="sewer_charge"
									value={formData.sewer_charge}
									onChange={handleChange}
								/>
							</div>
                            <div className="input-container">
								<label>Aplicado a:</label>
								<select name="id_medidor" value={formData.applied_to} onChange={handleChange}>
									<option value="">Aplicado a</option>
									{/* {medidores.map((medidor) => ( */}
										<option key={1} value={"consume_only"}>
											{/* {medidor.meter_addr} */}
                                            Solo consumo
										</option>
										<option key={2} value={"total"}>
											{/* {medidor.meter_addr} */}
                                            Total boleta
										</option>
									{/* ))} */}
								</select>
							</div>
							<div className="buttons-container">
								<button type="button" className="btn-cancel" onClick={() => navigate("/menu_apr")}>
									<img src={cancelIcon} className="cancel-image" />
									Cancelar
								</button>
								<button type="submit" className="btn-save">
									<img src={saveDisk} className="save-image" />
									Guardar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
