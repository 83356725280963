import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import authService from "./authService";

const user = JSON.parse(localStorage.getItem("user"));
const userInfo = JSON.parse(localStorage.getItem("userInfo"));


const initialState = {
	user: user ? user : null,
	userInfo: userInfo ? userInfo : null,
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: "",
};

export const register = createAsyncThunk(
	"auth/register",

	async (userData, thunkAPI) => {
		try {
			return await authService.register(userData);
		} catch (error) {
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const login = createAsyncThunk(
	"auth/login",
	async (userData, thunkAPI) => {
		try {
			return await authService.login(userData);
		} catch (error) {
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const logout = createAsyncThunk(
	"auth/logout",
	async () => {
		authService.logout();
	}
);

export const activate = createAsyncThunk(
	"auth/activate",
	async (userData, thunkAPI) => {
		try {
			return await authService.activate(userData);
		} catch (error) {
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const resetPassword = createAsyncThunk(
	"auth/resetPassword",
	async (userData, thunkAPI) => {
		try {
			return await authService.resetPassword(userData);
		} catch (error) {
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const resetPasswordConfirm = createAsyncThunk(
	"auth/resetPasswordConfirm",
	async (userData, thunkAPI) => {
		try {
			return await authService.resetPasswordConfirm(userData);
		} catch (error) {
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const getUserInfo = createAsyncThunk("auth/getUserInfo", async (_, thunkAPI) => {
	try {
		const accessToken = thunkAPI.getState().auth.user.access;
		return await authService.getUserInfo(accessToken);
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		reset: (state) => {
			// state.user = null;
			state.userInfo = null;
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = false;
			state.message = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(register.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(register.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.user = action.payload;
			})
			.addCase(register.rejected, (state, action) => {
				state.isLoading = false;
				state.isSuccess = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
			})
			.addCase(login.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.user = action.payload;
				localStorage.setItem("user", JSON.stringify(action.payload));
			})
			.addCase(login.rejected, (state, action) => {
				state.isLoading = false;
				state.isSuccess = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
			})
			.addCase(logout.fulfilled, (state) => {
				state.user = null;
				state.userInfo = null;
			})
			.addCase(activate.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(activate.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.user = action.payload;
			})
			.addCase(activate.rejected, (state, action) => {
				state.isLoading = false;
				state.isSuccess = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
			})
			.addCase(resetPassword.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(resetPassword.fulfilled, (state) => {
				state.isLoading = false;
				state.isSuccess = true;
			})
			.addCase(resetPassword.rejected, (state, action) => {
				state.isLoading = false;
				state.isSuccess = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
			})
			.addCase(resetPasswordConfirm.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(resetPasswordConfirm.fulfilled, (state) => {
				state.isLoading = false;
				state.isSuccess = true;
			})
			.addCase(resetPasswordConfirm.rejected, (state, action) => {
				state.isLoading = false;
				state.isSuccess = false;
				state.isError = true;
				state.message = action.payload;
				state.user = null;
			})
			.addCase(getUserInfo.fulfilled, (state, action) => {
				state.userInfo = action.payload;
			});
	},
});

export const {reset} = authSlice.actions;

export default authSlice.reducer;
