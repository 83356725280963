import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { BACKEND_DOMAIN } from "../../../../features/auth/authService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";

export default function ClientTypeEdit() {
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		code: "",
		movement_description: "",
	});

	const handleChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
	};


	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="my-profile-container">
					<div className="form-container">
						<h1 className="form-title">Editar tipo de estado</h1>
						<form className="edit-user-form" onSubmit={handleSubmit}>
							<div className="input-container">
								<label>Codigo estado</label>
								<input type="text" name="code" value={formData.code} onChange={handleChange} />
							</div>
							<div className="input-container">
								<label>Descripción de tipo de estado</label>
								<input
									type="text"
									name="movement_description"
									value={formData.movement_description}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								<label>Proceso de cálculo:</label>
								<select name="calculation_process" value={formData.applied_to} onChange={handleChange}>
									<option value="">Proceso de cálculo </option>
									{/* {medidores.map((medidor) => ( */}
									<option key={1} value={"yes"}>
										{/* {medidor.meter_addr} */}
										Si
									</option>
									<option key={2} value={"no"}>
										{/* {medidor.meter_addr} */}
										No
									</option>
									{/* ))} */}
								</select>
							</div>
							<div className="buttons-container">
								<button type="button" className="btn-cancel" onClick={() => navigate("/menu_apr")}>
									<img src={cancelIcon} className="cancel-image" />
									Cancelar
								</button>
								<button type="submit" className="btn-save">
									<img src={saveDisk} className="save-image" />
									Guardar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
