import React from "react";
import "../../../../stylesheets/pay.scss";
import { useNavigate } from "react-router-dom";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";

export default function Pay() {
	const navigate = useNavigate();

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<div className="user-info-container">
						<h3 className="info-text">
							Nombre: <span className="marked">Example</span>
						</h3>
						<h3 className="info-text">
							N° Medidor: <span className="marked">Example</span>
						</h3>
						<h3 className="info-text">
							RUT: <span className="marked">Example</span>
						</h3>
						<h3 className="info-text">
							Dirección: <span className="marked">Example</span>
						</h3>
						<h3 className="info-text">
							Estado medidor: <span className="marked">Example</span>
						</h3>
					</div>
				</div>
				<div className="payment-form-container">
					<h1 className="form-title">Ingresar pago</h1>
					<div className="grid-container">
						<div className="charges-container">
							<h2 className="form-subtitle">Detalles de cargos:</h2>
							<div class="index-list">
								<div class="index-item">
									<span class="label">Consumo de agua</span>
									<span class="dots"></span>
									<span class="value">10.000</span>
								</div>
								<div class="index-item">
									<span class="label">Cargo fijo alcantarillado</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
								<div class="index-item">
									<span class="label">Alcantarillado</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
								<br></br>
								<div class="index-item">
									<span class="label">Saldo anterior</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
								<div class="index-item">
									<span class="label">Convenio de pago</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
								<div class="index-item">
									<span class="label">Corte/Reposición</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
								<div class="index-item">
									<span class="label">Multa</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
								<div class="index-item">
									<span class="label">Cuota</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
								<div class="index-item">
									<span class="label">Incorporación</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
								<div class="index-item">
									<span class="label">Otros</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
							</div>
						</div>
						<div className="other-details">
							<h2 className="form-subtitle">Otros:</h2>
							<div class="index-list">
								<div class="index-item">
									<span class="label">IVA</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
								<div class="index-item">
									<span class="label">Ajuste sencillo</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
								<h2 className="form-subtitle">Detalles de pago:</h2>
								<div className="form-inputs">
									<div className="input-container" name="pay-date">
										<span class="label">Fecha de pago</span>
										<input type="date"></input>
									</div>
									<div className="input-container" name="pay-date">
										<span class="label">Tipo de pago</span>
										<select name="pay-type">
											<option value="">Seleccione el tipo de pago</option>
										</select>
									</div>
									<div className="input-container" name="pay-date">
										<span class="label">Forma de pago</span>
										<select name="pay-type">
											<option value="">Seleccione forma de pago</option>
										</select>
									</div>
									<div className="input-container" name="pay-date">
										<span class="label">N° de cheque</span>
										<input type="number"></input>
									</div>
									<div className="input-container" name="pay-date">
										<span class="label">Monto a pagar</span>
										<input type="number"></input>
									</div>
									<div className="horizontal-bottom-container">
										<div className="input-container" name="pay-date">
											<span class="label">Dinero entregado</span>
											<input type="number"></input>
										</div>
										<div className="input-container" name="pay-date">
											<span class="label">Vuelto</span>
											<input type="number"></input>
										</div>
									</div>
									<div className="form-footer-container">
										<div className="labels-container">
											<span class="label">Total boleta: 43.000</span>
											<span class="label">Pagado real: 43.000</span>
											<span class="label">Por Pagar: 0</span>
										</div>
										<div className="buttons-container">
											<button type="button" className="btn-cancel" onClick={() => navigate("/menu_apr")}>
												<img src={cancelIcon} className="cancel-image" />
												Cancelar
											</button>
											<button type="submit" className="btn-save">
												<img src={saveDisk} className="save-image" />
												Guardar
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="discount-details">
							<h2 className="form-subtitle">Detalles de descuentos:</h2>
							<div class="index-list">
								<div class="index-item">
									<span class="label">Subsidios</span>
									<span class="dots"></span>
									<span class="value">10.000</span>
								</div>
								<div class="index-item">
									<span class="label">Otros descuentos</span>
									<span class="dots"></span>
									<span class="value">0</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
