import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getUserInfo, login, reset } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import Spinner from "./Spinner";

import "../stylesheets/login.scss";

export default function Login() {
	const [formData, setFormData] = useState({
		rut: "", // RUT sin formato para enviar al backend
		password: "",
	});

	const { rut, password } = formData;
	const [formattedRut, setFormattedRut] = useState(""); // RUT formateado para mostrar en el input

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { isLoading, isError, isSuccess, message, userInfo } = useSelector((state) => state.auth);

	// Función para formatear el RUT dinámicamente
	const formatRut = (value) => {
		const cleanValue = value.replace(/[^0-9kK]/g, ""); // Elimina caracteres no válidos
		if (cleanValue.length <= 1) return cleanValue; // No formatear si tiene menos de 2 caracteres

		const cuerpo = cleanValue.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		const dv = cleanValue.slice(-1).toUpperCase();

		return `${cuerpo}-${dv}`;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "rut") {
			// Actualiza el RUT sin formato
			const cleanRut = value.replace(/[^0-9kK]/g, ""); // Mantiene solo números y K
			setFormData((prev) => ({ ...prev, [name]: cleanRut }));

			// Actualiza el RUT formateado
			setFormattedRut(formatRut(cleanRut));
		} else {
			// Actualiza cualquier otro campo
			setFormData((prev) => ({ ...prev, [name]: value }));
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const userData = {
			rut, // Se envía el RUT sin formato al backend
			password,
		};
		dispatch(login(userData));
	};

	useEffect(() => {
		dispatch(reset());
		dispatch(getUserInfo());
	}, [dispatch]);

	useEffect(() => {
		if (isSuccess) {
			dispatch(getUserInfo()).then(() => {
				if (userInfo?.user_type === "client") {
					navigate("/menu");
				} else if (userInfo?.user_type === "admin_apr") {
					navigate("/menu_apr");
				}
			});
		} else if (isError) {
			toast.error(message);
		}
	}, [isError, isSuccess, userInfo, navigate, message]);

	return (
		<>
			<div className="background-overlap" />
			<div className="auth-container">
				<h1 className="main-title">Iniciar Sesión</h1>

				{isLoading && <Spinner />}

				<form className="auth-form" onSubmit={handleSubmit}>
					<div className="input-container">
						<h2 className="input-title">Rut</h2>
						<input
							className="input-box"
							type="text"
							name="rut"
							placeholder="Introduce tu numero de rut. Ej: 11.111.111-1"
							onChange={handleChange}
							value={formattedRut} // Muestra el RUT formateado
							required
						/>
					</div>
					<div className="input-container">
						<h2 className="input-title">Contraseña</h2>
						<input
							className="input-box"
							type="password"
							name="password"
							placeholder="Introduce tu contraseña"
							onChange={handleChange}
							value={password}
							required
						/>
					</div>

					<div className="bottom-container">
						<button className="btn-primary" type="submit">
							Iniciar Sesión
						</button>
						<Link to="/reset-password" className="forget-password-link">
							¿Olvidaste tu contraseña?
						</Link>
					</div>
				</form>
			</div>
		</>
	);
}
