import React, {useState} from "react";
import {NavLink, Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout, reset} from "../../features/auth/authSlice";

// IMAGE IMPORT
import homeIcon from "../../images/home-icon-white.svg";
import profileIcon from "../../images/profile-icon-white.svg";
import watermeterIcon from "../../images/watermeter-icon-white.svg";
import logo from "../../images/logo.svg";
import logoutIcon from "../../images/logout-icon-white.svg";
import buildingIcon from "../../images/building-icon-white.svg";
import usersIcon from "../../images/users-icon-white.svg";
import fileZipper from "../../images/file-zipper-white.svg";

import {AppstoreOutlined, MailOutlined, SettingOutlined} from "@ant-design/icons";
import {Menu} from "antd";

export default function MenuNav() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [stateOpenKeys, setStateOpenKeys] = useState(["2", "23"]);

	const handleLogout = () => {
		dispatch(logout());
		dispatch(reset());
		navigate("/");
	};

	const {userInfo} = useSelector((state) => state.auth);

	const items = [
		{
			key: "1",
			// icon: <img className="icon" src={homeIcon} />,
			label: (
				<NavLink to="/menu_apr" end className={"option-container"} activeclass="active">
					<img src={homeIcon} className="option-icon" />
					<h3 className="option-text">Inicio</h3>
				</NavLink>
			),
		},
		{
			key: "2",
			// icon: <img className="icon" src={buildingIcon} />,
			label: (
				<NavLink to="/menu_apr/profile" className={"option-container"} activeclass="active">
					<img src={buildingIcon} className="option-icon" />
					<h3 className="option-text">Perfil APR</h3>
				</NavLink>
			),
		},
		{
			key: "3",
			icon: <img className="option-icon" src={watermeterIcon} />,
			label: (
				<div className="option-container">
					<h3 className="option-text">Medidores</h3>
				</div>
			),
			children: [
				{
					key: "31",
					label: (
						<NavLink to="/menu_apr/watermeters_list" className={"option-container"} activeclass="active">
							<h3 className="option-text">Lista de medidores</h3>
						</NavLink>
					),
				},
				{
					key: "32",
					label: (
						<NavLink to="/menu_apr/watermeters_outlets" className={"option-container"} activeclass="active">
							<h3 className="option-text">Tipos de arranques</h3>
						</NavLink>
					),
				},
				{
					key: "33",
					label: (
						<NavLink to="/menu_apr/fares" className={"option-container"} activeclass="active">
							<h3 className="option-text">Tarifas</h3>
						</NavLink>
					),
				},
				{
					key: "34",
					label: (
						<NavLink to="/menu_apr/sectors" className={"option-container"} activeclass="active">
							<h3 className="option-text">Sectores</h3>
						</NavLink>
					),
				},
				{
					key: "35",
					label: (
						<NavLink to="/menu_apr/movements_list" className={"option-container"} activeclass="active">
							<h3 className="option-text">Tipos de Movimientos</h3>
						</NavLink>
					),
				},
				{
					key: "36",
					label: (
						<NavLink to="/menu_apr/clients_states" className={"option-container"} activeclass="active">
							<h3 className="option-text">Estados clientes</h3>
						</NavLink>
					),
				},
				{
					key: "37",
					label: (
						<NavLink to="/menu_apr/payment_methods" className={"option-container"} activeclass="active">
							<h3 className="option-text">Métodos de pago</h3>
						</NavLink>
					),
				},
			],
		},
		{
			key: "4",
			icon: <img className="option-icon" src={usersIcon} />,
			label: (
				<div className="option-container">
					<h3 className="option-text">Usuarios</h3>
				</div>
			),
			children: [
				{
					key: "41",
					label: (
						<NavLink to="/menu_apr/users_list" className={"option-container"} activeclass="active">
							<h3 className="option-text">Lista de usuarios</h3>
						</NavLink>
					),
				},
				{
					key: "42",
					label: (
						<NavLink to="/menu_apr/clients_movements" className={"option-container"} activeclass="active">
							<h3 className="option-text">Movimientos clientes</h3>
						</NavLink>
					),
				},
				{
					key: "43",
					label: (
						<NavLink to="/menu_apr/requirements_spreadsheets" className={"option-container"} activeclass="active">
							<h3 className="option-text">Planillas por requisito</h3>
						</NavLink>
					),
				},
				{
					key: "44",
					label: (
						<NavLink to="/menu_apr/reading_entries" className={"option-container"} activeclass="active">
							<h3 className="option-text">Ingreso de lecturas</h3>
						</NavLink>
					),
				},
			],
		},
	];

	const getLevelKeys = (items) => {
		const key = {};
		const func = (items, level = 1) => {
			items.forEach((item) => {
				if (item.key) {
					key[item.key] = level;
				}
				if (item.children) {
					func(item.children, level + 1);
				}
			});
		};
		func(items);
		return key;
	};

	const levelKeys = getLevelKeys(items);

	const onOpenChange = (openKeys) => {
		const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
		// open
		if (currentOpenKey !== undefined) {
			const repeatIndex = openKeys.filter((key) => key !== currentOpenKey).findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

			setStateOpenKeys(
				openKeys
					// remove repeat key
					.filter((_, index) => index !== repeatIndex)
					// remove current level all child
					.filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
			);
		} else {
			// close
			setStateOpenKeys(openKeys);
		}
	};

	return (
		<>
			<div className="option-bar-container">
				<div className="logo-container">
					<img className="logo" src={logo} />
				</div>
				<div className="options-container">
					{userInfo?.user_type === "client" ? (
						<>
							<NavLink to="/menu" end className={"option-container"} activeclass="active">
								<img src={homeIcon} className="option-icon" />
								<h3 className="option-text">Inicio</h3>
							</NavLink>
							<NavLink to="/menu/profile" className={"option-container"} activeclass="active">
								<img src={profileIcon} className="option-icon" />
								<h3 className="option-text">Mi perfil</h3>
							</NavLink>
							<NavLink to="/menu/watermeter" className={"option-container"} activeclass="active">
								<img src={watermeterIcon} className="option-icon" />
								<h3 className="option-text">Estado Medidor</h3>
							</NavLink>
						</>
					) : userInfo?.user_type === "admin_apr" ? (
						<>
							<Menu mode="inline" defaultSelectedKeys={["1"]} openKeys={stateOpenKeys} onOpenChange={onOpenChange} items={items} />
							{/* <NavLink to="/menu_apr" end className={"option-container"} activeclass="active">
								<img src={homeIcon} className="option-icon" />
								<h3 className="option-text">Inicio</h3>
							</NavLink>
							<NavLink to="/menu_apr/profile" className={"option-container"} activeclass="active">
								<img src={buildingIcon} className="option-icon" />
								<h3 className="option-text">Perfil APR</h3>
							</NavLink>
							<NavLink to="/menu_apr/watermeters_list" className={"option-container"} activeclass="active">
								<img src={watermeterIcon} className="option-icon" />
								<h3 className="option-text">Medidores</h3>
							</NavLink>

							<NavLink to="/menu_apr/users_list" className={"option-container"} activeclass="active">
								<img src={usersIcon} className="option-icon" />
								<h3 className="option-text">Usuarios</h3>
							</NavLink>
							<NavLink to="/menu_apr/file_api" className={"option-container"} activeclass="active">
								<img src={fileZipper} className="option-icon" />
								<h3 className="option-text">Importar/Exportar archivos</h3>
							</NavLink> */}
						</>
					) : (
						""
					)}
				</div>
				<Link className="logout-container" to="/" onClick={handleLogout}>
					<img className="logout-icon" src={logoutIcon} />
					<h3 className="logout-text">Cerrar Sesión</h3>
				</Link>
			</div>
		</>
	);
}
