import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {BACKEND_DOMAIN} from "../../features/auth/authService";
import {Button, Input, Space, Table} from "antd";
import Highlighter from "react-highlight-words";
import {SearchOutlined} from "@ant-design/icons";

export default function Consumptions() {
	const {userId} = useParams();
	const [data, setData] = useState([]); // Estado para almacenar los consumos
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");

	useEffect(() => {
		const fetchMedidores = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				const token = user?.access;

				if (!token) {
					throw new Error("No se encontró el token en el localStorage");
				}

				const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/consumo/usuario/${userId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				const consumptions = response.data.consumos;
				console.log("consumptions", consumptions);

				// Mapeo de los datos a la estructura requerida por antd
				const mappedData = consumptions.map((consumo, index) => {
					const formattedDate = new Date(consumo.read_date).toLocaleString("es-ES", {
						hour: "2-digit",
						minute: "2-digit",
						day: "2-digit",
						month: "2-digit",
						year: "numeric",
					});

					return {
						key: index,
						status: consumo.valve_status,
						read_date: formattedDate, // Formatear la fecha
						solicitud_cerrar: consumo.solicitud_cerrar,
						consumo: consumo.meter_number,
						voltage: consumo.voltage,
					};
				});

				setData(mappedData);
			} catch (error) {
				console.error("Error fetching medidores:", error);
			}
		};

		fetchMedidores();
	}, []);

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
			<div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{marginBottom: 8, display: "block"}}
				/>
				<Space>
					<Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{width: 90}}>
						Search
					</Button>
					<Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{width: 90}}>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({closeDropdown: false});
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm();
						}}>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? "#1677ff" : undefined}} />,
		onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const columns = [
		{
			title: "Estado",
			dataIndex: "status",
			key: "status",
			width: "10%",
			...getColumnSearchProps("status"),
			render: (_, record) => (
				<Space size="middle">
					{record.status === "Opened" ? (
						<div className="active-state">Activo</div>
					) : record.state === "Inactivo" ? (
						<div className="inactive-state">Inactivo</div>
					) : record.state === "Revisar" ? (
						<div className="review-state">Revisión</div>
					) : (
						""
					)}
				</Space>
			),
		},
		{
			title: "Fecha",
			dataIndex: "read_date",
			key: "read_date",
			width: "15%",
			...getColumnSearchProps("read_date"),
		},
		{
			title: "Consumo",
			dataIndex: "consumo",
			key: "consumo",
			width: "15%",
			...getColumnSearchProps("consumo"),
		},
		{
			title: "Voltaje",
			dataIndex: "voltage",
			key: "voltage",
			width: "20%",
			...getColumnSearchProps("voltage"),
		},
	];

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<Table columns={columns} dataSource={data} />
			</div>
		</div>
	);
}
