import { Button, Input, Space, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../images/plus-icon.svg";

export default function Fares() {
	const [data, setData] = useState([]);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const navigate = useNavigate();

	const handleEditClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
		navigate(`/menu_apr/edit_fare/1`);
	};

	const handleAddClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
		navigate(`/menu_apr/edit_fare/1`);
	};

	const handleDeleteClick = (userId) => {
		// navigate(`/menu_apr/consumptions/${userId}`);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}
					>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "Código",
			dataIndex: "code",
			key: "code",
			width: "10%",
			...getColumnSearchProps("code"),
		},
		{
			title: "Descripción tarifa",
			dataIndex: "fare_description",
			key: "fare_description",
			width: "20%",
			...getColumnSearchProps("fare_description"),
		},
		{
			title: "Porcentaje descuento",
			dataIndex: "discount_percentage",
			key: "discount_percentage",
			width: "25%",
			...getColumnSearchProps("discount_percentage"),
			sorter: (a, b) => a.discount_percentage.length - b.discount_percentage.length,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Monto tope",
			dataIndex: "cap_mount",
			key: "cap_mount",
			width: "15%",
			...getColumnSearchProps("cap_mount"),
		},
		{
			title: "Acción",
			dataIndex: "edit",
			key: "edit",
			render: (_, record) => (
				<div className="actions-container">
					<a className="active-link" onClick={() => handleEditClick(record.key)}>
						Editar
					</a>
					<a className="review-link" onClick={() => handleDeleteClick(record.key)}>
						Eliminar
					</a>
				</div>
			),
		},
	];

	useEffect(() => {
		// Datos temporales
		const tempData = [
			{
				key: "1",
				code: "1",
				fare_description: "Comun",
				discount_percentage: "25",
				cap_mount: "4000",
			},
			{
				key: "2",
				code: "2",
				fare_description: "Subsidio 1",
				discount_percentage: "0",
				cap_mount: "0",
			},
		];
		setData(tempData);
	}, []);

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<h1 className="container-title">Tarifas</h1>
					<div className="add-button-container">
						<img className="plus-icon" src={plusIcon} />
						<a className="add-button" onClick={() => handleAddClick("")}>
							Añadir tarifa
						</a>
					</div>
				</div>
				<Table columns={columns} dataSource={data} />
			</div>
		</div>
	);
}
