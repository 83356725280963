import {NavLink, Link} from "react-router-dom";
import logoHeader from "../images/logo.svg";
import "../stylesheets/footer.scss";
import lightblueDivisor from "../images/lightblue-divisor.svg";

export default function Footer() {
	return (
		<div className="footer">
			<div className="superior-footer">
				<div className="logo-section-container">
					<NavLink to="/" activeclass="active">
						<div className="logo-container">
							<img src={logoHeader} />
						</div>
					</NavLink>
					<h2 className="footer-description">
						Control en tiempo real sobre calidad de presión , estado de válvulas , cobro y pago de consumos , medidores inteligentes , consulta de datos ,
						compra de insumos - todo mientras ahorras tiempo y dinero.
					</h2>
				</div>
				<ul className="lista-nav">
					<li className="main-list-element">
						<NavLink to="/solutions" className="main-navlink" activeclass="active">
							Soluciones
						</NavLink>
						<ul className="sub-nav">
							<Link className="sub-nav-link">
								<li>Análisis</li>
							</Link>
							<Link className="sub-nav-link">
								<li>Dispositivos MyF</li>
							</Link>
							<Link className="sub-nav-link">
								<li>Monitoreo MyF</li>
							</Link>
						</ul>
					</li>
					<li className="main-list-element">
						<NavLink to="/resources" activeclass="active" className="main-navlink">
							Recursos
						</NavLink>
						<ul className="sub-nav">
							<Link className="sub-nav-link">
								<li>Centro de Soporte</li>
							</Link>
							<Link className="sub-nav-link">
								<li>Planes</li>
							</Link>
							<Link className="sub-nav-link">
								<li>Guías</li>
							</Link>
							<Link className="sub-nav-link">
								<li>Blog</li>
							</Link>
						</ul>
					</li>
					<li className="main-list-element">
						<NavLink to="/contact" activeclass="active" className="main-navlink">
							Contáctanos
						</NavLink>
						<ul className="sub-nav">
							<li>
								Direccion Falsa 123123 <br />
								Coquimbo , Sindempart
							</li>
							<li>correofalso123@gmail.com</li>
						</ul>
					</li>
				</ul>
			</div>
			<img src={lightblueDivisor} />
			<div className="inferior-footer">
				<div className="copyright-container">
					<h3 className="copyright-text">Copyright © 2024 MyF SpA. Todos los derechos reservados.</h3>
					<h3 className="copyright-text">
						MyF y otras marcas comerciales, marcas de servicio y diseños son propiedad registrada o no registrada marcas comerciales de MyF, SpA. en Chile y
						otros países.
					</h3>
				</div>
				<div className="terms-container">
					<h2 className="terms-text">Reclamar términos de privacidad</h2>
				</div>
			</div>
		</div>
	);
}
