import React, {useRef} from "react";
import excelIcon from "../../images/excel-file-type.svg";

export default function FileApi() {
	const fileInputRef = useRef(null);

	const handleFileClick = () => {
		fileInputRef.current.click(); // Simula el click en el input
	};

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="my-profile-container">
					<div className="editable-information-container file-api">
						<div class="planillas-container">
							<h2>Archivos</h2>
							<div className="consume-spreadsheets">
								<div className="subir-planilla">
									<label>
										Subir planilla de consumos <img src={excelIcon} className="icon-upload" />{" "}
									</label>
									<input type="file" ref={fileInputRef} style={{display: "none"}} />
									<button className="upload-button" onClick={handleFileClick}>
										Seleccionar archivo
									</button>
								</div>

								<div class="descargar-planilla">
									<label>
										Descargar modelo de planilla <img src={excelIcon} className="icon-upload" />
									</label>
									<button class="download-button">Descargar</button>
								</div>
							</div>

							<div class="descargar-boletas">
								<h2>Descargar boletas</h2>
								<label for="fecha-desde">Rango de fechas de boletas</label>
								<div class="rango-fechas">
									<div class="fecha-inputs">
										<label for="fecha-desde">Desde</label>
										<input type="date" id="fecha-desde" value="2024-03-27" />
										<label for="fecha-hasta">Hasta</label>
										<input type="date" id="fecha-hasta" value="2024-03-27" />
									</div>
									<button class="download-button">
										<i class="icon-download"></i> Descargar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
