import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "../../stylesheets/menu.scss";
import {NavLink} from "react-router-dom";
import {BACKEND_DOMAIN} from "../../features/auth/authService";

import graphicPLACEHOLDER from "../../images/GRAPHIC-PLACEHOLDER.SVG";
import axios from "axios";
import {getUserInfo, reset} from "../../features/auth/authSlice";

export default function MenuApr() {
	const {userInfo} = useSelector((state) => state.auth);
	const [meterInfo, setMeterInfo] = useState([]);
	const [totalMeterNumber, setTotalMeterNumber] = useState(0);
	const [valveStatusCounts, setValveStatusCounts] = useState({status1: 0, status2: 0});
	const dispatch = useDispatch();

	// console.log("userInfo", userInfo);

	useEffect(() => {
		// console.log("useEffect")
		dispatch(reset());
		dispatch(getUserInfo());
	}, []);

	useEffect(() => {
		const fetchMeterInfo = async () => {
			try {
				// Consulta al nuevo endpoint usando el token Bearer
				const user = JSON.parse(localStorage.getItem("user")); // Asume que 'user' tiene el token
				const token = user?.access; // Accede al token de acceso
				const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/getmedidores/apr/`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				const meters = response.data;
				setMeterInfo(meters);
				// console.log(meters)
				// Sumar todos los "meter_number"
				const totalMeterNumber = meters.reduce((acc, meter) => acc + meter.consumo.meter_number, 0);

				// Contar "valve_status": 1 y "valve_status": 2	
				const valveStatus1 = meters.filter((meter) => meter.consumo.valve_status === "Opened").length;
				const valveStatus2 = meters.filter((meter) => meter.consumo.valve_status === "Closed").length;

				// Renderizar estos valores más adelante en el componente
				setTotalMeterNumber(totalMeterNumber);
				setValveStatusCounts({status1: valveStatus1, status2: valveStatus2});
			} catch (error) {
				console.error("Error fetching meter info:", error);
			}
		};

		if (userInfo) {
			fetchMeterInfo();
		}
	}, []);

	const formatRut = (rut) => {
		// Convertir el rut a string para asegurar su manipulación como cadena
		const rutStr = String(rut).trim();

		// Obtener el dígito verificador y los dígitos del rut
		const rutDigits = rutStr.slice(0, -1);
		const dv = rutStr.slice(-1);

		// Formatear los dígitos con puntos
		let formattedRut = rutDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

		// Agregar el dígito verificador
		formattedRut = `${formattedRut}-${dv}`;

		return formattedRut;
	};

	const formattedRut = formatRut(userInfo?.rut);

	return (
		<>
			<div className="menu-container">
				<div className="menu-content-container">
					{/* <h1>Bienvenido, {userInfo.first_name} </h1> */}

					<div className="top-gray-container">
						<h1 className="container-title">Inicio</h1>
						<div className="api-info-container">
							<div className="info-content">
								<h1 className="content-text">--</h1>
								<h2 className="content-subtext">Consumo hoy</h2>
							</div>
							<div className="info-content">
								<h1 className="content-text">{totalMeterNumber}</h1>
								<h2 className="content-subtext">Consumo total</h2>
							</div>
							<div className="info-content">
								<h1 className="content-text">{valveStatusCounts.status1}</h1>
								<h2 className="content-subtext">Medidores abiertos</h2>
							</div>
							<div className="info-content">
								<h1 className="content-text">{valveStatusCounts.status2}</h1>
								<h2 className="content-subtext">Medidores cerrados</h2>
							</div>
						</div>
					</div>
					<div className="bottom-container">
						<div className="graphic-container">
							<img className="graphic-placeholder-temp" src={graphicPLACEHOLDER} />
						</div>
						<div className="user-info-container">
							<h2 className="user-info-title">Información</h2>
							<h3 className="user-info-text">Región: {userInfo?.region_name}</h3>
							<h3 className="user-info-text">Comuna: {userInfo?.comuna_name}</h3>
							<h3 className="user-info-text">Sector: {userInfo?.sector_name}</h3>
							<h3 className="user-info-text">Dirección: {userInfo?.address} </h3>
							<h3 className="user-info-text">Nombre: {userInfo?.first_name + " " + userInfo?.last_name} </h3>
							<h3 className="user-info-text">Rut: {formattedRut} </h3>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
