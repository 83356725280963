import React from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function NotFound() {
	return (
		<>
			<Helmet>
				<title>Home</title>
				<meta name="description" content="This is 404" />
			</Helmet>
			<h1>404 - Not Found</h1>
			<hr />
			<p>Sorry, the page you are lookin for does not exist.</p>
			<p>
				<Link to="/">Back</Link>
			</p>
		</>
	);
}
