import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import "../../stylesheets/menu.scss";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {BACKEND_DOMAIN} from "../../features/auth/authService";

import graphicPLACEHOLDER from "../../images/GRAPHIC-PLACEHOLDER.SVG";
import {getUserInfo, reset} from "../../features/auth/authSlice";

export default function MenuUser() {
	const {userInfo} = useSelector((state) => state.auth);
	const [meterInfo, setMeterInfo] = useState(null);
	const [lorawanInfo, setLorawanInfo] = useState(null); // Estado para la segunda consulta
	const dispatch = useDispatch();

	// console.log("userInfo", userInfo);

	const formatRut = (rut) => {
		// Convertir el rut a string para asegurar su manipulación como cadena
		const rutStr = String(rut).trim();

		// Obtener el dígito verificador y los dígitos del rut
		const rutDigits = rutStr.slice(0, -1);
		const dv = rutStr.slice(-1);

		// Formatear los dígitos con puntos
		let formattedRut = rutDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

		// Agregar el dígito verificador
		formattedRut = `${formattedRut}-${dv}`;

		return formattedRut;
	};

	const formattedRut = formatRut(userInfo?.rut);

	useEffect(() => {
		// console.log("useEffect")
		dispatch(reset());
		dispatch(getUserInfo());
	}, []);
	useEffect(() => {
		const fetchMeterInfo = async () => {
			try {
				// Primera consulta para obtener la información del medidor
				const responseMeter = await axios.get(`${BACKEND_DOMAIN}/api/v1/medidores/${userInfo.assigned_meter}`);
				const meterData = responseMeter.data;
				setMeterInfo(meterData);

				// Segunda consulta, usando meter_addr de la primera consulta
				if (meterData && meterData.meter_addr) {
					const responseLorawan = await axios.get(`${BACKEND_DOMAIN}/api/v1/lorawan/meterinfo/${meterData.meter_addr}`);
					const lorawanData = responseLorawan.data;

					// Guardar los datos obtenidos de la segunda consulta en el estado y en el localStorage
					setLorawanInfo(lorawanData);
					localStorage.setItem("lorawanInfo", JSON.stringify(lorawanData)); // Almacenar en localStorage
				}
			} catch (error) {
				console.error("Error fetching meter or lorawan info:", error);
			}
		};

		// Verificar si los datos ya están en localStorage
		const storedLorawanInfo = localStorage.getItem("lorawanInfo");

		if (storedLorawanInfo) {
			// Si hay datos en localStorage, usarlos
			// console.log("storedLorawanInfo", JSON.parse(storedLorawanInfo))
			setLorawanInfo(JSON.parse(storedLorawanInfo));
			// console.log("lorawanInfo", lorawanInfo)
		} else if (userInfo && userInfo?.assigned_meter) {
			// Si no hay datos en localStorage, hacer la consulta
			fetchMeterInfo();
		}
	}, [userInfo]);

	// console.log("userInfo", userInfo);
	// console.log("meterInfo", meterInfo);
	// console.log("lorawanInfo", lorawanInfo);

	return (
		<>
			<div className="menu-container">
				<div className="menu-content-container">
					{/* <h1>Bienvenido, {userInfo.first_name} </h1> */}

					<div className="top-gray-container">
						<h1 className="container-title">Inicio</h1>
						<div className="api-info-container">
							{lorawanInfo ? (
								<>
									<div className="info-content">
										<h1 className="content-text">{lorawanInfo.data.Voltage}</h1>
										<h2 className="content-subtext">Voltaje</h2>
									</div>
									<div className="info-content">
										<h1 className="content-text">{lorawanInfo.data.ValveStatus === 1 ? "Opened" : "Closed"}</h1>
										<h2 className="content-subtext">Estado</h2>
									</div>
									<div className="info-content">
										<h1 className="content-text">{lorawanInfo.data.MeterNumber}</h1>
										<h2 className="content-subtext">Consumo leido</h2>
									</div>
									<div className="info-content centered">
										<h1 className="content-text">
											{lorawanInfo.data.ReadDate.split(" ")[0]}
											<br />
											<span className="small">{lorawanInfo.data.ReadDate.split(" ")[1]}</span>
										</h1>
										<h2 className="content-subtext">
											Fecha <br />
											<span className="small">Última lectura</span>
										</h2>
									</div>
								</>
							) : (
								<p>Cargando información del medidor...</p>
							)}
						</div>
					</div>
					<div className="bottom-container">
						<div className="graphic-container">
							<img className="graphic-placeholder-temp" src={graphicPLACEHOLDER} />
						</div>
						<div className="user-info-container">
							<h2 className="user-info-title">Información usuario</h2>
							<h3 className="user-info-text">Región: {userInfo?.region_name}</h3>
							<h3 className="user-info-text">Comuna: {userInfo?.comuna_name}</h3>
							<h3 className="user-info-text">Sector: {userInfo?.sector_name}</h3>
							<h3 className="user-info-text">Dirección: {userInfo?.address} </h3>
							<h3 className="user-info-text">Nombre: {userInfo?.first_name + " " + userInfo?.last_name} </h3>
							<h3 className="user-info-text">Rut: {formattedRut} </h3>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
