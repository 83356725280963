import {Button, Input, Space, Table} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {useNavigate} from "react-router-dom";

export default function ReadingEntries() {
	const [data, setData] = useState([]);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const navigate = useNavigate();

	const handleEditClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
	};

	const handleDeleteClick = (userId) => {
		// navigate(`/menu_apr/consumptions/${userId}`);
	};

	const handleAgreementClick = (userId) => {
		// navigate(`/menu_apr/consumptions/${userId}`);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
			<div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{marginBottom: 8, display: "block"}}
				/>
				<Space>
					<Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{width: 90}}>
						Search
					</Button>
					<Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{width: 90}}>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({closeDropdown: false});
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm();
						}}>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? "#1677ff" : undefined}} />,
		onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "N° Cliente",
			dataIndex: "client_number",
			key: "client_number",
			width: "10%",
			...getColumnSearchProps("client_number"),
		},
		{
			title: "N° Medidor",
			dataIndex: "watermeter_number",
			key: "watermeter_number",
			width: "20%",
			...getColumnSearchProps("watermeter_number"),
		},
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			width: "15%",
			...getColumnSearchProps("name"),
		},
		{
			title: "Lect. Anterior",
			dataIndex: "pre",
			key: "previous_lecture",
			width: "25%",
			...getColumnSearchProps("previous_lecture"),
			sorter: (a, b) => a.previous_lecture.length - b.previous_lecture.length,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Lect. Actual",
			dataIndex: "actual_lecture",
			key: "actual_lecture",
			width: "15%",
			...getColumnSearchProps("actual_lecture"),
		},
		{
			title: "Sin Lectura",
			dataIndex: "no_lecture",
			key: "no_lecture",
			width: "15%",
			...getColumnSearchProps("no_lecture"),
		},
		{
			title: "Agregar lect. promedio",
			dataIndex: "add_default",
			key: "add_default",
			width: "15%",
			...getColumnSearchProps("add_default"),
		},
		{
			title: "Consumo",
			dataIndex: "consumption",
			key: "consumption",
			width: "15%",
			...getColumnSearchProps("consumption"),
		},
	];

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<Table columns={columns} dataSource={data} />
			</div>
		</div>
	);
}
