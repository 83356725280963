import {BrowserRouter as Router, Routes, Route, Outlet} from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import NotFound from "./components/NotFound";
import Resources from "./components/Resources";
import Login from "./components/Login";
import Solutions from "./components/Solutions";

import "./App.scss";
import MenuUser from "./components/menu/MenuUser";
import {ToastContainer} from "react-toastify";
import MenuNav from "./components/menu/MenuNav";
import WatermeterList from "./components/menu/WatermetersList";
import ClientsStates from "./components/menu/watermeters_submenu/ClientsStates";
import Fares from "./components/menu/watermeters_submenu/Fares";
import MovementsList from "./components/menu/watermeters_submenu/MovementsLists";
import PaymentMethods from "./components/menu/watermeters_submenu/PaymentMethods";
import Sectors from "./components/menu/watermeters_submenu/Sectors";
import WatermetersOutlets from "./components/menu/watermeters_submenu/WatermetersOutlets";

import UserLists from "./components/menu/UsersLists";
import ClientsMovements from "./components/menu/users_submenu/ClientsMovements";
import ReadingEntries from "./components/menu/users_submenu/ReadingEntries";
import RequirementsSpreadsheets from "./components/menu/users_submenu/RequirementsSpreadsheets";

import MenuApr from "./components/menu/MenuApr";
import Profile from "./components/menu/Profile";
import ProfileApr from "./components/menu/ProfileApr";
import Watermeter from "./components/menu/Watermeter";
import EditUser from "./components/menu/EditUser";
import AddUser from "./components/menu/users_submenu/forms/AddUser";
import AddLecture from "./components/menu/AddLecture";
import Consumptions from "./components/menu/Consumptions";
import FileApi from "./components/menu/FileApi";
import WatermeterOutletEdit from "./components/menu/watermeters_submenu/forms/WatermeterOutletEdit";
import FareEdit from "./components/menu/watermeters_submenu/forms/FareEdit";
import SectorEdit from "./components/menu/watermeters_submenu/forms/SectorEdit";
import MovementEdit from "./components/menu/watermeters_submenu/forms/MovementEdit";
import ClientTypeEdit from "./components/menu/watermeters_submenu/forms/ClientTypeEdit";
import PaymentMethodEdit from "./components/menu/watermeters_submenu/forms/PaymentMethodEdit";
import MonthlyLectures from "./components/menu/users_submenu/MonthlyLectures";
import Pay from "./components/menu/users_submenu/forms/Pay";

function App() {
	return (
		<>
			<Router>
				<Routes>
					<Route path="/" element={<WithNavAndFooter />}>
						<Route index element={<Home />} />
						<Route path="/solutions" element={<Solutions />} />
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/resources" element={<Resources />} />
						<Route path="/login" element={<Login />} />
						<Route path="*" element={<NotFound />} />
					</Route>

					<Route path="/menu" element={<WithoutNavAndFooter />}>
						<Route index element={<MenuUser />} />
						<Route path='/menu/profile' element={<Profile />}/>
						<Route path='/menu/watermeter' element={<Watermeter />}/>
					</Route>

					<Route path="/menu_apr" element={<WithoutNavAndFooter />}>
						<Route index element={<MenuApr />} />
						<Route path='/menu_apr/profile' element={<ProfileApr />}/>
						
						{/* WATERMETER SUBMENU */}
						<Route path='/menu_apr/watermeters_list' element={<WatermeterList />}/>
						<Route path='/menu_apr/watermeters_outlets' element={<WatermetersOutlets />}/>
						<Route path="/menu_apr/edit_watermeter_outlet/1" element={<WatermeterOutletEdit />} />
						<Route path='/menu_apr/fares' element={<Fares />}/>
						<Route path="/menu_apr/edit_fare/1" element={<FareEdit />} />
						<Route path='/menu_apr/sectors' element={<Sectors />}/>
						<Route path="/menu_apr/edit_sector/1" element={<SectorEdit />} />
						<Route path='/menu_apr/movements_list' element={<MovementsList />}/>
						<Route path="/menu_apr/edit_movement/1" element={<MovementEdit />} />
						<Route path='/menu_apr/clients_states' element={<ClientsStates />}/>
						<Route path="/menu_apr/edit_client_type/1" element={<ClientTypeEdit />} />
						<Route path='/menu_apr/payment_methods' element={<PaymentMethods />}/>
						<Route path="/menu_apr/edit_payment_method/1" element={<PaymentMethodEdit />} />

						{/* USERS SUBMENU */}
						<Route path='/menu_apr/users_list' element={<UserLists />}/>
						<Route path='/menu_apr/clients_movements' element={<ClientsMovements />}/>
						<Route path='/menu_apr/reading_entries' element={<ReadingEntries />}/>
						<Route path='/menu_apr/requirements-spreadsheets' element={<RequirementsSpreadsheets />}/>
						<Route path='/menu_apr/monthly-lectures/1' element={<MonthlyLectures />}/>
						<Route path='/menu_apr/pay/' element={<Pay />}/>



						<Route path='/menu_apr/file_api' element={<FileApi />}/>
						<Route path="/menu_apr/editar-usuario/:userId" element={<EditUser />} />
						<Route path="/menu_apr/add-user/" element={<AddUser />} />
						<Route path="/menu_apr/consumptions/:userId" element={<Consumptions />} />
						<Route path="/menu_apr/agregar-lectura/:meterId" element={<AddLecture />} />
					</Route>
				</Routes>
			</Router>
			<ToastContainer />
		</>
	);
}

function WithNavAndFooter() {
	return (
		<>
			<div className="main">
				<Nav />
				<Outlet /> {/* Aquí se renderizarán los elementos hijos de las rutas */}
				<Footer />
			</div>
		</>
	);
}

function WithoutNavAndFooter() {
	return (
		<>
			<div className="background-overlap" />
			<div className="main-menu">
				<MenuNav />
				<Outlet />
			</div>
		</>
	);
}

export default App;
